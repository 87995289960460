<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'10'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>矿山管理</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>矿山列表</span>
				</div>
        <!--
				<div class="left-list-item" @click="leftClick('1')">
					<span>备件分类</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('2')">
					<span>备件数据</span>
				</div>
				-->
			</div>
			<div class="right-next">
				<div class="date-table">
					<div class="title">
						<span>{{title}}</span>
					</div>
          <div> <el-button type="danger" @click="back()">返回</el-button></div>
					<div class="edit-form">
						<!--<div class="item">
							<div class="label">所属备件分类:</div>
							<el-select v-model="editForm.pid" clearable placeholder="请选择所属备件分类">
								<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>-->
						<div class="item">
							<div class="label">备件数据名称:</div>
							<el-input v-model="editForm.name" placeholder="备件数据名称"></el-input>
						</div>
						<div class="item">
							<!--  @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
								@ready="onEditorReady($event)" @change="onEditorChange($event)" -->
							<div class="label">备件数据概括:</div>
							<textarea v-model="editForm.generalization" placeholder="备件数据概括"></textarea>
							<!-- <quill-editor class="editor" ref="myTextEditor" v-model="editForm.generalization"
								:options="editorOption">
							</quill-editor> -->
						</div>
						<div class="item">
							<div class="label">备件数据文件:</div>
							<el-upload class="avatar-uploader" action="url" :show-file-list="true" ref="upload" :headers="uploadHeaders" :auto-upload="false"
								:http-request="uploadFile" accept=".pdf,.doc,.docx,.png,.jpg,.xls,.xlsx">
								<el-button size="small" type="primary">点击上传</el-button>
								<div slot="tip" class="el-upload__tip">只能上传pdf/doc/docx/jpg/png/xls/xlsx文件</div>
							</el-upload>
						</div>
						<div class="item submit">
							<el-button type="primary" @click="submitUpload" v-loading.fullscreen.lock="fullscreenLoading">保存添加</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../../components/leftList.vue";
	import topHeader from "../../../components/header.vue"
	export default {
		components: {
			leftList,
			topHeader
		},
		inject: ["reload"],
		data() {
			return {
				fullscreenLoading:false,
				options: [],
				typeList:[],
				title: '新增备件数据',
				isCollapse: false,
				uploadHeaders: {
					"Remember-Token": localStorage.getItem("rememberToken"),
				},
				editForm: {
					name: '',
					url: '',
					generalization: '',
					mine_id: '',
					type_id:0,
					size:'',
          pid:''
				},
				formDate: '',
        fileArray:new Array(),
			};
		},
		created() {
			if (this.$route.query.id) {
				this.title = '编辑备件数据';
				this.getinfo();
			}
			this.gettypeList();
		},
		methods: {
			getinfo() {
				this.$get('mine_file/get_details', {
					id: this.$route.query.id
				}).then(res => {
					console.log(res);
					this.editForm.name = res.data.data.company_name;
				})
			},
			gettypeList(){
				this.$get('mine_file/get_list', {
					page: 1,
					num: 999,
          mine_id:this.$route.query.mine_id,
          type_id:1,
          pid:this.$route.query.pid,
				}).then(res => {
					this.typeList = res.data.data.data;
				})
			},
      back(){
        this.$router.go(-1);
      },
			submitUpload() {
				let _this = this;
				if (this.editForm.name == "") {
					this.$notify.error({
						title: "提示",
						message: "请填写备件数据名称！",
					});
					return false;
				}
				if (this.editForm.generalization == '') {
					this.$notify.error({
						title: "提示",
						message: "请填写备件数据概括！",
					});
					return false;
				}
				// if (this.editForm.url == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "请上传矿山数据文件！",
				// 	});
				// 	return false;
				// }
				this.fullscreenLoading = true;
				this.formDate = new FormData();
				_this.$refs.upload.submit();
				this.formDate.append("mine_id", this.$route.query.mine_id);
				this.formDate.append("type_id", 0);
        this.formDate.append("pid", this.$route.query.pid);
				this.formDate.append("name", this.editForm.name);
				this.formDate.append("details", this.editForm.generalization);
        this.formDate.append("file_length", this.fileArray.length);
        for (let i = 0; i < this.fileArray.length; i++) {
          this.formDate.append("file"+i, this.fileArray[i]);
          this.formDate.append("file"+i+"name", this.fileArray[i].name);
          this.formDate.append("filesize"+i, parseFloat(this.fileArray[i].size / 1024 / 1024).toFixed(2));
        }

				let config = {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				};
				if (this.$route.query.id) {
					this.formDate.append("id", this.$route.query.id);
					this.$axios
						.post(this.$store.state.httpUrl + "upd/mine_file", this.formDate, config)
						.then((res) => {
							this.fullscreenLoading = false;
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.$router.push({
                  query: {
                    mine_id: this.$route.query.mine_id,
                    pid:this.$route.query.pid,
                  },
                  name:"minefile"});
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							// console.log(res);
						})
						.catch((res) => {
							this.fullscreenLoading = false;
							console.log(res);
						});
				} else {
					this.$axios
						.post(this.$store.state.httpUrl + "add/mine_file", this.formDate, config)
						.then((res) => {
							this.fullscreenLoading = false;
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.$router.push({
                  query: {
                    mine_id: this.$route.query.mine_id,
                    pid:this.$route.query.pid,
                  },
                  name:"minefile"});
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							// console.log(res);
						})
						.catch((res) => {
							this.fullscreenLoading = false;
							console.log(res);
						});
				}
        this.fileArray = new Array();
			},
			uploadFile(file) {
        //console.log(file.file);
        let fileArrayTemp = this.fileArray;
        fileArrayTemp.push(file.file);
        this.fileArray = fileArrayTemp;
        //this.formDate.append("files", file.file);
        console.log(this.fileArray);
        // eslint-disable-next-line no-mixed-spaces-and-tabs
			 },
			// uploadChange(file, fileList) {
			// 	//console.log(file, fileList);
			// 	//this.editForm.size = parseFloat(file.size / 1024 / 1024).toFixed(2);
			// 	//this.editForm.url = URL.createObjectURL(file.raw);
      //   let file_array = new Array();
      //   fileList.forEach((item)=>{
      //     file_array.push(item.raw);
      //     //console.log(file_array);
      //   })
      //   this.fileArray = file_array;
      //   console.log(this.fileArray);
			// },

			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/mine") {
					this.$router.push("/mine");
				} else if(index == 1 && this.$route.path != "/minetype"){
					this.$router.push("/minetype");
				} else if(index == 2 && this.$route.path != "/minefile"){
					this.$router.push("/minefile");
				} else {
					this.reload();
				}
			},
		},
	};
</script>

<style lang="scss">
	.quill-editor {
		margin-top: 20px;
		width: 80%;
	}

	.ql-editor {
		height: 230px;
	}

	.avatar-uploader {
		display: inline-block;
		vertical-align: top;
	}

	.avatar {
		width: 178px;
		height: 178px;
		// display: block;
	}

	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.date-table {
			margin-bottom: 20px;

			.operation {
				margin-top: 30px;

				.el-button {
					width: 100px;
				}
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 20px;
		}

		.edit-form {
			.submit {
				width: 80%;
				text-align: center;

				.el-button {
					width: 150px;
				}
			}

			.item {
				margin-top: 30px;

				.label {
					display: inline-block;
					vertical-align: middle;
					width: 150px;
				}

				textarea {
					vertical-align: top;
					-webkit-appearance: none;
					background-color: #FFF;
					background-image: none;
					border-radius: 4px;
					border: 1px solid #DCDFE6;
					box-sizing: border-box;
					color: #606266;
					display: inline-block;
					font-size: inherit;
					height: 300px;
					outline: 0;
					padding: 15px;
					transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
					width: 500px;
					resize: none;

					&:focus {
						border-color: #409EFF;
						outline: 0;
					}
				}

				.el-input {
					width: 230px;
				}
			}
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
